<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card pd_1">
        <div class="card-header">
          <b-row align-v="center" slot="header">
            <b-col cols="8">
              <h2 class="mb-0 no_print">
                <i class="fas fa-list"></i> ข้อมูลใบสั่งซื้อ
              </h2>
            </b-col>
          </b-row>
        </div>
        <div class="card-body pd_1">
          <div class="pb-4 text-right no_print">
            <span
              ><router-link :to="'/order'" class="nav-link text-underline">
                กลับ</router-link
              ></span
            >
            <button
              type="button"
              class="btn btn-warning style_btn"
              @click="clickPrint()"
            >
              <i class="fas fa-print"></i>
            </button>
          </div>

          <!-- กำหนดให้ ลูบ ตามรายงาน -->
          <template v-for="(item, index) in items">
            <div
              v-for="copy_type in copyTypes"
              :key="copy_type.key"
              class="card custom-card page-break"
            >
              <div class="card-body">
                <div class="row">
                  <div
                    class="col-md-9 p-4 fs_18 grid_2"
                    style="font-size: 25px; width: 70%"
                  >
                    <h1
                      class="mb-3 text-left fs-20 text-bold"
                      :style="{ color: copy_type.color }"
                    >
                      {{ copy_type.key + ": " + copy_type.name }}
                    </h1>
                    <div class="mb-4 fs_18">
                      <div>{{ item.store.name }}</div>
                    </div>
                    <div class="mb-4 fs_18" style="font-size: 25px">
                      {{ item.supplier.name }}
                    </div>
                  </div>
                  <!-- <div  class="col-lg-6 flex_column grid_1 fs_25 lh_4 pt-4 border border-dark"></div> -->
                  <div class="col-md-3" style="width: 30%">
                    <div class="mb-4 text-left" style="font-size: 24px">
                      <div>ออเดอร์ : {{ item.order.code }}</div>
                      <div>วันที่ส่ง : {{ item.order.delivery_date }}</div>
                      <div>
                        รอบส่ง :
                        <span
                          class="font-weight-bold"
                          :class="{
                            'background-yellow text-dark':
                              item.order.expected_delivery_time === '07:00:00',
                            'background-pink text-dark':
                              item.order.expected_delivery_time === '12:00:00',
                            'background-blue text-dark':
                              item.order.expected_delivery_time === '16:00:00',
                            'background-green text-dark':
                              item.order.expected_delivery_time === '20:00:00',
                            'text-danger': ![
                              '07:00:00',
                              '12:00:00',
                              '16:00:00',
                              '20:00:00',
                            ].includes(item.order.expected_delivery_time),
                          }"
                        >
                          {{ item.order.expected_delivery_time }}
                        </span>
                      </div>
                      <BarCode
                        :value="item.order.code"
                        :displayValue="false"
                      ></BarCode>
                    </div>
                  </div>
                  <div class="col-12 border-top border-dark"></div>
                  <div class="col-lg-3 align-self-center flex_column grid_3">
                    <div class="p-3"></div>
                    <div class="pb-2">
                      <img
                        :src="funcs.isImgEmpty(item.product_img)"
                        class="img-thumbnail"
                        style="width: 270px"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 flex_column grid_3 fs_25 lh_4 pt-4">
                    <h3>สินค้าหลัก</h3>
                    <div
                      class="mb_55"
                      style="margin-bottom: 10px; margin-top: 10px"
                    >
                      <input
                        type="checkbox"
                        class="form-control"
                        style="width: 20px; height: 20px; display: inline"
                      />&nbsp;
                      <span class="fs_40"
                        >รหัสสินค้า :
                        <span style="font-size: 20px">{{
                          item.product.code
                        }}</span></span
                      >
                    </div>
                    <div v-for="tag in item.product.tags" :key="tag.value">
                      <template
                        v-if="
                          tag.key === 'ประเภท' ||
                          tag.key === 'ชนิดดอกไม้' ||
                          tag.key === 'สี'
                        "
                      >
                        <div class="mb_55" style="margin-bottom: 10px">
                          <input
                            type="checkbox"
                            clas="form-control"
                            style="
                              width: 20px;
                              height: 20px;
                              vertical-align: middle;
                            "
                          />
                          &nbsp;
                          <span>{{ tag.key }} : {{ tag.value }}</span>
                        </div>
                      </template>
                    </div>
                    <div class="mb_55">
                      <input
                        type="checkbox"
                        clas="form-control"
                        style="
                          width: 20px;
                          height: 20px;
                          vertical-align: middle;
                        "
                      />
                      &nbsp;
                      <span class="fs_40"
                        >จำนวนดอก :
                        <span style="font-size: 20px">{{
                          item.product.size
                        }}</span></span
                      >
                    </div>
                  </div>
                  <div
                    class="col-lg-3 grid_3 border-left border-dark fs_18 lh_4 pt-4"
                  >
                    <div class="mb_55">
                      <div>ชื่อแมส</div>
                      <br />
                      <div>..............................................</div>
                    </div>
                    <div class="mb_55">
                      <div>เวลาออก</div>
                      <br />
                      <div>..............................................</div>
                    </div>
                    <div>
                      <div>เวลาถึง</div>
                      <br />
                      <div>..............................................</div>
                    </div>
                  </div>
                  <div class="col-12 border-top border-dark"></div>
                  <div
                    class="col-md-12 border-dark border-bottom"
                    style="height: 280px"
                  >
                    <div class="col-md-12 pt-4"></div>
                    <div class="row col-md-12">
                      <div
                        class="col-lg-4 align-self-center flex_column grid_3"
                        v-for="add_ons in item.product.add_ons"
                        :key="add_ons.value"
                        v-if="item.product.add_ons.length > 0"
                      >
                        <div class="p-3">
                          <input
                            type="checkbox"
                            class="form-control"
                            style="width: 20px; height: 20px; display: inline"
                          />&nbsp;
                          <span class="fs_40"
                            >รหัสสินค้า :
                            <span
                              >{{ add_ons.product_code }} <br />{{
                                add_ons.product_name
                              }}</span
                            ></span
                          >
                        </div>
                        <div class="pb-2">
                          <img
                            :src="funcs.isImgEmpty(add_ons.imgs[0].url)"
                            class="img-thumbnail"
                            style="width: 130px"
                          />
                        </div>
                      </div>
                      <div
                        class="col-12 flex_column grid_3"
                        v-if="item.product.add_ons.length === 0"
                      >
                        <div
                          style="
                            color: #868282;
                            font-size: 30px;
                            text-align: center;
                            margin-top: 40px;
                          "
                        >
                          ไม่มีสินค้า Add On
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 pt-4"></div>
                  <div class="col-12 fs_18 pt-2" style="line-height: 1.7">
                    <div class="mb_55 row">
                      <div class="col-2">
                        <input
                          type="checkbox"
                          clas="form-control"
                          style="
                            width: 20px;
                            height: 20px;
                            vertical-align: middle;
                          "
                        />
                        &nbsp; ข้อความการ์ด :
                      </div>
                      <div class="col">
                        {{ truncatedMsg(item.product.msg_fr) }} <br />
                        {{ item.product.msg_se }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="m-4"
                    style="background-color: #e2e2e2; width: 100%"
                  >
                    <div class="row" style="background-color: #e2e2e2">
                      <div class="col-md-12 text-center">
                        <h1 class="text-dark">
                          เขตจัดส่ง : {{ item.order.delivery_location }}
                        </h1>
                      </div>
                      <div class="col-5 flex_column grid_3 fs_18">
                        <div class="my-4">
                          <div class="row">
                            <div class="col-6 mb-3">
                              <label
                                class="form-label"
                                style="font-weight: bold"
                                >ผู้รับ :
                                {{ item.order.delivery_contact }}</label
                              >
                            </div>
                            <div class="col-6 mb-3">
                              <label
                                class="form-label"
                                style="font-weight: bold"
                                >เบอร์ : {{ item.order.delivery_phone }}</label
                              >
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6 mb-3">
                              <label
                                class="form-label"
                                style="font-weight: bold"
                                >ผู้รับ#2 :
                                {{
                                  item.order.seconadry_receipient.name || ""
                                }}</label
                              >
                            </div>
                            <div class="col-6 mb-3">
                              <label
                                class="form-label"
                                style="font-weight: bold"
                                >เบอร์ :
                                {{
                                  item.order.seconadry_receipient.name || ""
                                }}</label
                              >
                            </div>
                          </div>
                          <div class="row" v-if="copy_type.key != 'M'">
                            <div class="col-6 mb-3">
                              <label
                                class="form-label"
                                style="font-weight: bold"
                                >ผู้ส่ง : {{ item.order.customer_name }}</label
                              >
                            </div>
                            <div class="col-6 mb-3">
                              <label
                                class="form-label"
                                style="font-weight: bold"
                                >เบอร์ : {{ item.customer.phone }}</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :class="
                          item.order.google_map_url == null ? 'col-6' : 'col-5'
                        "
                      >
                        <div class="mb-3">
                          <label
                            class="form-label m-4"
                            style="font-weight: bold"
                            >ที่อยู่ :
                            <b>{{ item.order.delivery_address }}</b></label
                          >
                        </div>
                      </div>
                      <div class="col-2" v-if="item.order.google_map_url">
                        <QRCodeVue :value="item.order.google_map_url""
                        :size="140" />
                      </div>
                      <div class="col-12 mx-2">
                        <label class="form-label" style="font-weight: bold"
                          >ข้อเน้นย้ำ :</label
                        >
                        {{ truncatedMsg(item.order.remark) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Display full msg_fr at the beginning of the loop -->
              <div
                v-if="
                  copy_type.key === 'A' &&
                  (item.product.msg_fr.length > 270 ||
                    item.order.remark.length > 270)
                "
                class="col-12 mb-3"
              >
                <div class="card-body">
                  <div class="row">
                    <h2 class="text-bold">ข้อความการ์ดทั้งหมด:</h2>
                    <p class="text-wrap">{{ item.product.msg_fr }}</p>
                    <h2 class="text-bold">ข้อเน้นย้ำทั้งหมด:</h2>
                    <p class="text-wrap">{{ item.order.remark }}</p>
                  </div>
                </div>
              </div>
            </div> </template
          ><!-- End Loop -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as scripts from "../../../util/scripts";
import moment, { updateLocale } from "moment";
import StringUtils from "../../../util/StringUtils";
import DateUtils from "../../../util/dateUtils";
import Constants from "../../../util/constants";
import BarCode from "../component/barcode.vue";
import QRCodeVue from "qrcode.vue";

export default {
  components: {
    BarCode,
    QRCodeVue,
  },
  name: "order-view-page-bill-order",
  data() {
    return {
      noImageData: Constants.noImageData,
      permission: {
        appSlug: "order",
        actionData: {},
      },
      StringUtils: StringUtils,
      ids: [],
      items: [],
      item: {
        customer: null,
        store: null,
        sale: null,
        products: [],
        store_id: 1,
        store_name: "Love You Flower",
        delivery_date: "14/02/2022",
        delivery_period: 1,
        order_code: "O-0500055",
        // products: [{
        product_code: "V227",
        product_img: "",
        product_qty: 5,
        // product_code_more: '',
        // product_name_more: '',
        remark: "",
        card_msg:
          "วันธรรมดา หายใจออกมาเป็นไอ วันวาเลนไทน์หายใจออกมาเป็นเธอ รักนะ จากคนที่คุณก็รู้ว่าใคร",
        // }],
        messager_name: "",
        time_out: "",
        time_back: "",
        sender_name: "Carra",
        sender_phone: "0123456789",
        addressee_name: "ณัฐกาม่อน",
        addressee_phone: "0987654321",
        delivery_time: "08:00",
        delivery_address:
          "บริษัท แอพบิท สตูดิโอ จำกัด เลขที่ 114/1 ซอยปราโมทย์2 ถนนมเหสักข์แขวงสุริยวงศ์เขตบางรัก กรุงเทพฯ 10500",
        key_copy_type: "A",
        supplier_name: "Vday ร้านดอกไม",
        categories: "ดอกกุหลาบ",
        colors: "สีชมพู",
        qty: "5 ดอก",
        city_name: "กทม.",
        district_name: "ราชเทวี",
        subdistrict_name: "ทุ่งพญาไท",
        detail:
          "อย่าให้เขานั้นรู้ว่าฉันเคยเป็นใคร อย่าให้เขาสงสัยเรื่องที่ผ่านมา",
        receiver_name: "คุณเบนซ",
        receiver_phone: "0876543210",
        receiver_address:
          "บริษัท แอพบิท สตูดิโอ จำกัด เลขที่ 114/1 ซอยปราโมทย์2 ถนนมเหสักข์แขวงสุริยวงศ์เขตบางรัก กรุงเทพฯ 10500",
      },
      periods: [
        {
          id: 1,
          text: "08.00-13.00",
        },
        {
          id: 2,
          text: "14.00-19.00",
        },
      ],
      funcs: scripts.funcs,
      copyTypes: [
        {
          key: "A",
          name: "สำหรับคนไปร้าน",
          color: "#000000",
        },
        {
          key: "S",
          name: "สำหรับซัพพลายเออร์",
          color: "#C70039",
        },
        {
          key: "M",
          name: "สำหรับแมสเซนเจอร์ ",
          color: "#1c30fa",
        },
      ],
    };
  },
  computed: {
    periodName() {
      let time = this.item.delivery_time;
      if (time == "08:00") {
        return "รอบเช้า";
      } else if (time == "13:00") {
        return "รอบบ่าย";
      } else if (time == "18:00") {
        return "รอบเย็น";
      } else if (time == "") {
        return "";
      } else {
        // เวลาอื่นๆ
        return "รอบพิเศษ";
      }
    },
    getTypeCopyName() {
      let obj = {
        name: "",
      };
      obj = this.copyTypes.find((row) => {
        if (row.key == this.item.key_copy_type) {
          return row;
        }
      });
      return obj.name;
    },
  },
  methods: {
    disabledTimeOut(date) {
      if (this.item.time_back) {
        return date > this.item.time_back;
      }
      return false;
    },
    disabledTimeBack(date) {
      if (this.item.time_out) {
        return date < this.item.time_out;
      }
      return false;
    },
    clickPrint() {
      this.updatePrintStatus();
      window.print();
    },
    updatePrintStatus() {
      this.HttpServices.putData(
        `/ab_order/updatePrintedDatetime/${this.ids.join(",")}`
      );
    },
    getLabelPeriod(period) {
      let result = "";
      for (let i in this.periods) {
        if (period == this.periods[i].id) {
          result = this.periods[i].text;
        }
      }
      return result;
    },
    getFormatTime(date) {
      let result = "";
      if (date) {
        result = moment(date).format("HH:mm");
      }
      return result;
    },
    truncatedMsg(msg) {
      if (msg && msg.length > 270) {
        return msg.substring(0, 270) + " (มีต่อ...)";
      }
      return msg;
    },
  },
  async mounted() {
    await this.PermissionServices.viewPermission(
      this.permission.appSlug,
      this.$router
    );
    this.permission.actionData = await this.PermissionServices.getPermission(
      this.permission.appSlug
    );
    let item = await this.SessionStorageUtils.getSession("page-order-selected");
    this.ids = item.split(",");

    const result = await this.HttpServices.getData(
      `/ab_order/getMultipleOrder?ids=${this.ids}`
    );
    if (result && result.status.code == "200") {
      this.items = result.data;

      for (let i in this.items) {
        if (this.items[i].order.expected_delivery_date) {
          this.items[i].order.expected_delivery_date = DateUtils.dateFormat(
            this.items[i].order.expected_delivery_date,
            "DD/MM/YYYY"
          );
        }

        if (this.items[i].product.imgs != "") {
          let imgs = JSON.parse(this.items[i].product.imgs);
          this.items[i].product_img = imgs[0].url;
        }

        if (this.items[i].order.expected_delivery_time) {
          let hour = parseInt(this.items[i].order.delivery_hour);
          console.log(hour);
          if (hour >= 8 && hour <= 11) {
            this.items[i].periodName = "รอบเช้า";
          } else if (hour >= 12 && hour <= 15) {
            this.items[i].periodName = "รอบบ่าย";
          } else if (hour >= 16 && hour <= 19) {
            this.items[i].periodName = "รอบเย็น";
          } else if (hour >= 20 && hour <= 22) {
            this.items[i].periodName = "รอบค่ำ";
          } else if (time == "") {
            this.items[i].periodName = "";
          } else {
            // เวลาอื่นๆ
            this.items[i].periodName = "รอบพิเศษ";
          }
        } else {
          this.items[i].periodName = "";
        }

        let new_tag = [];

        // Add (because forget declare let at var j)
        for (let j in this.items[i].product.tags) {
          // // Old
          // for(j in this.items[i].product.tags){
          if (
            this.items[i].product.tags[j].key == "ประเภท" ||
            this.items[i].product.tags[j].key == "ชนิดดอกไม้" ||
            this.items[i].product.tags[j].key == "สี"
          ) {
            new_tag.push(this.items[i].product.tags[j]);
          }
        }

        //this.items[i].periodName = time;
      }

      /*
     
      this.service = this.item.service;
      if(this.item.expected_delivery_date){
        this.item.expected_delivery_date = DateUtils.dateFormat(this.item.expected_delivery_date, "DD/MM/YYYY");
      }
      if(this.item.products){
        this.products = JSON.parse(JSON.stringify(this.item.products));
        for(const v of this.products){
          if(v.logoImg){
            v.logoImg = `${v.logoImg}`;
          }
          if(v.imageDisplays&&v.imageDisplays.length>0){
            const images = [];
            for(const vv of v.imageDisplays){
              images.push(`${vv}`);
            }
            v.imageDisplays = images;
            v.imageFiles = [];

            for(const vv of v.products){
              if(vv.logoImg){
                vv.logoImg = `${vv.logoImg}`;
              }
              if(vv.imageDisplays&&vv.imageDisplays.length>0){
                const images = [];
                for(const ss of v.imageDisplays){
                  images.push(`${ss}`);
                }
                vv.imageDisplays = images;
                vv.imageFiles = [];
              }
            }
          }
        }
      }
      */
    } else {
      this.AlertUtils.alertCallback("warning", `ไม่พบข้อมูลการสั่งซื้อ`, () => {
        this.$router.push("/order");
      });
    }
  },
};
</script>
<style scoped>
.card .table.bill_order td {
  padding-left: 4px;
  padding-right: 4px;
  white-space: inherit;
}

@media print {
  .page-break {
    page-break-after: always;
  }
}
.background-yellow {
  background-color: #fffd74;
  color: black; /* Adjust text color for contrast */
}
.background-pink {
  background-color: #f8c8dc;
  color: black;
}
.background-blue {
  background-color: #7df9ff;
  color: black;
}
.background-green {
  background-color: #4fffb0;
  color: black;
}
.background-red {
  background-color: red;
  color: white;
}
.text-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 100%;
}
</style>
